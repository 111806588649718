/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import type { RudderAnalytics } from "@rudderstack/analytics-js";

export let RUDDER_ANALYTICS: RudderAnalytics | null = null;

// Yes this is weird. Don't touch it unless Rudderstack has fixed their library.
// This library has an unprotected call to SessionStorage which causes it to error during import.
// When that happens, we fully stop code execution, causing our application to simply return a white page.
const loadRudderstack = async (): Promise<void> => {
  const rudder = await import("@rudderstack/analytics-js");
  RUDDER_ANALYTICS = new rudder.RudderAnalytics();
};

void loadRudderstack().catch((e) => {
  console.error("Failed to import RudderStack analytics", e);
});
